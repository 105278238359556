<script>
import Customer from '@school/services/API/Customer';

export default {
  props: {
    training: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    localPath: null,
  }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    href() {
      return `
        ${this.$env.apiResourcesURL}/${this.certificatePath}
      `.trim();
    },
    certificatePath() {
      return this.training.certificate_path || this.localPath;
    },
  },
  methods: {
    generate() {
      this.isLoading = true;
      Customer.generateCertificate(this.training.slug)
        .then(({ data }) => (this.localPath = data.certificate_path))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div>
    <b-button
      v-if="certificatePath"
      tag="a"
      class="has-text-success tdecoration-underline"
      type="is-text"
      icon-left="award"
      :href="href"
      target="_blank"
      rel="noreferrer noopener"
      download="download">
      Voir mon certificat
    </b-button>
    <b-button
      v-else
      type="is-primary"
      class="is-custom"
      icon-left="award"
      :loading="isLoading"
      @click="generate">
      Générer mon certificat
    </b-button>
  </div>
</template>
