<script>
import RegisterForm from '@school/components/RegisterForm.vue';
import CertificateButton from '@school/components/CertificateButton.vue';
import moment from 'moment';

export default {
  props: {
    trainings: {
      type: Array,
      required: true,
    },
  },
  components: { RegisterForm, CertificateButton },
  data: () => ({ registerFormIsOpen: false }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
  },
  methods: {
    onEnroll(training) {
      this.registerFormIsOpen = false;
      training.customer_type = 'STUDENT';
      this.$buefy.dialog.alert(`
        Vous pouvez désormais accéder à la formation ${training.name}
        dans l'onglet "Mes formations" du tableau de bord.
      `);
    },
    showExpirationDelay(customerTraining) {
      return customerTraining.expiration
        && moment().isBefore(customerTraining.expiration)
        && customerTraining.customer_type === 'STUDENT';
    },
  },
};
</script>

<template>
  <section class="ctlcomp">
    <p v-if="trainings.length <= 0" class="notification">
      Vous n'avez rejoint aucune formation de cet espace, ou vos accès à la/les formation(s) ont expiré.
    </p>
    <template v-else>
      <article v-for="one in trainings" :key="one.uuid" class="box media is-block-mobile">
        <div class="media-content content mb-0">
          <p class="is-size-8" :class="showExpirationDelay(one) ? 'mb-0' : ''">
            <b-icon class="-ml-1" icon="calendar-check" pack="far" />
            <template v-if="one.last_activity_at">
              Consultée {{ one.last_activity_at | momentFromUTC | moment('from') }}
              •
            </template>
            <b-tooltip>
              <template #content>
                le {{ one.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              Inscrit le {{ one.created_at | momentFromUTC | moment('DD/MM/YY') }}
            </b-tooltip>
          </p>
          <p v-if="showExpirationDelay(one)" class="is-size-8">
            <b-icon class="-ml-1" icon="clock" pack="far" />
            <b-tooltip>
              <template #content>
                le {{ one.expiration | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              Expire le {{ one.expiration | momentFromUTC | moment('DD/MM/YY') }}
            </b-tooltip>
          </p>
          <h3 class="mt-0 is-size-6-touch">
            <router-link
              class="color-inherit"
              :class="{'pevents-none': one.customer_type == 'BLOCKED'}"
              :to="{name: 'mytraining', params: {slug: one.slug}}"
            >
              {{ one.name }}
            </router-link>
          </h3>
          <p v-if="one.summary" class="is-size-7-touch">
            {{ one.summary }}
          </p>
          <template v-if="one.customer_type == 'STUDENT' || one.customer_type == 'BLOCKED'">
            <p class="is-flex is-align-items-center is-size-8">
              <span class="mr-5">
                {{ one.completed_module_count }} / {{ one.total_module_count }} leçons
                ({{ Math.round(one.completed_module_count / one.total_module_count * 100) }}%)
              </span>
              <span class="is-flex-grow-1">
                <b-progress
                  class="w-full"
                  type="is-success"
                  size="is-small"
                  :value="one.completed_module_count"
                  :max="one.total_module_count"
                />
              </span>
            </p>
          </template>
        </div>
        <nav class="ctlcomp_cta media-right is-align-self-center ml-20 mt-5 has-text-centered">
          <template v-if="one.customer_type == 'STUDENT' || one.customer_type == 'BLOCKED'">
            <p v-if="one.customer_type == 'STUDENT'">
              <template v-if="!one.completed_at">
                <b-button
                  v-if="one.started_at"
                  type="is-primary is-custom"
                  tag="router-link"
                  :to="{name: 'mytrainingitem', params: {slug: one.slug, id: one.last_activity_training_item_id}}"
                  expanded
                >
                  Continuer
                </b-button>
                <b-button
                  v-else
                  type="is-primary is-custom"
                  tag="router-link"
                  :to="{name: 'mytrainingitem', params: {slug: one.slug, id: one.first_training_item_id}}"
                  expanded
                >
                  Commencer
                </b-button>
              </template>
              <b-button
                v-if="one.started_at"
                class="mt-3"
                type="is-text"
                tag="router-link"
                :to="{name: 'mytraining', params: {slug: one.slug}}"
                expanded
              >
                Voir la fiche
              </b-button>
            </p>
            <template v-if="one.completed_at">
              <p v-if="!store.certificate_options.disabled || one.certificate_path" class="mb-3">
                <CertificateButton :training="one" />
              </p>
              <p v-else key="see_again">
                <b-button
                  type="is-primary is-custom"
                  tag="router-link"
                  :to="{name: 'mytraining', params: {slug: one.slug}}"
                  expanded
                >
                  Revoir la formation
                </b-button>
              </p>
            </template>
          </template>
          <template v-else>
            <b-button
              type="is-primary is-custom"
              tag="router-link"
              :to="{name: 'training', params: {slug: one.slug}}"
            >
              Finaliser mon inscription
            </b-button>
            <RegisterForm
              v-if="registerFormIsOpen"
              :buyable="one"
              @enroll="onEnroll(one)"
              @complete="registerFormIsOpen = false"
              @close="registerFormIsOpen = false"
            />
          </template>

          <template v-if="one.customer_type == 'BLOCKED' && one.blocked_reason == 'BY_TEACHER'">
            <div class="tag is-dark">
              Formation bloquée par le formateur
            </div>
          </template>
          <template v-else-if="one.customer_type == 'BLOCKED' && one.blocked_reason == 'BY_EXPIRATION'">
            <div class="tag is-dark">
              Accès à la formation expiré
            </div>
          </template>
        </nav>
      </article>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.ctlcomp {
  @include mobile {
    &_cta {
      margin-left: 0 !important;
    }
  }
}
</style>
